import { format } from 'date-fns';
import { responseHandler } from './responseHandler';
import {
  toPeriod,
  toDisplayString,
  PeriodToString,
  PeriodToTC,
} from '../utils/date';
import {
  currentProgressSerializer,
  listSerializer,
} from './serializers/appointment.serializer';
import { t } from 'src/i18n/config';
import { isOpenApi, getApiUrl } from './utils';

import request from './request';

const API_URL = getApiUrl();

export const getSchedules = ({ startDate, endDate }) => {
  let url = isOpenApi()
    ? `${API_URL}/internal/schedules?startTime=${startDate}`
    : `${API_URL}/api/schedules?startDate=${startDate}`;
  if (endDate) {
    if (isOpenApi()) {
      url = url + `&endTime=${endDate}`;
    } else {
      url = url + `&endDate=${endDate}`;
    }
  }
  return request(url, { method: 'GET' });
};

export const geOpenApiHisSeqTimes = (scheduleID) => {
  const url = `${API_URL}/internal/seqTimes?scheduleID=${scheduleID}`;
  return request(url, { method: 'GET' });
};

export const getHisSeqTimes = (
  date,
  period,
  doctorId,
  roomId,
  subjectId,
  scheduleId
) => {
  const url = `${API_URL}/api/seqTimes?date=${date}&period=${period}&doctorId=${doctorId}&roomId=${roomId}&subjectId=${subjectId}&scheduleId=${scheduleId}`;
  return request(url, {
    method: 'GET',
  });
};

export const createAppointment = (appointment) => {
  const url = isOpenApi()
    ? `${API_URL}/internal/appointments`
    : `${API_URL}/api/appointment/booking`;

  return request(url, {
    method: 'POST',
    payload: appointment,
  }).then((res) => {
    if (typeof res === 'string') {
      throw new Error(res);
    }
    return res;
  });
};

export const cancelAppointment = ({ appointment }) => {
  const { id } = appointment;
  const url = isOpenApi()
    ? `${API_URL}/internal/appointments/${id}`
    : `${API_URL}/api/appointment/${id}`;

  return request(url, {
    method: 'DELETE',
  }).then((res) => {
    if (typeof res === 'string' && res !== '') {
      throw new Error(res);
    }
    return res;
  });
};

export const listAppointments = () => {
  const url = isOpenApi()
    ? `${API_URL}/internal/appointments`
    : `${API_URL}/api/appointment/history`;

  return request(url, {
    method: 'GET',
  }).then(listSerializer);
};

// @TODO: 只有 aomori 有這
export const fetchCurrentProgressStatus = async (list) => {
  const body = list.map(({ time: period, doctorName, date, room: roomId }) => ({
    period,
    doctorName,
    date: toDisplayString(date),
    roomId,
  }));
  const url = `${API_URL}/api/vendor/progress`;
  return request(url, {
    method: 'POST',
    body: JSON.stringify(body),
  })
    .then(responseHandler)
    .then(currentProgressSerializer(list));
};

const progressConvert = ({
  doctorName,
  roomId,
  roomID,
  waitingCount,
  currentSeq,
  period,
  date,
  subjectName,
  updatedAt,
  waitingCode,
}) => ({
  id: `${roomId || roomID}-${doctorName}`,
  date: new Date(date),
  time: PeriodToTC(period),
  periodString: PeriodToString(period),
  doctorName: doctorName,
  subject: subjectName || t('uncategorized'),
  room: roomId || roomID,
  waitingCount,
  currentSeq,
  updatedAt: new Date(updatedAt),
  waitingCode,
});

const progressSerializer = (res) => {
  return res.map(progressConvert).reduce((s, t) => {
    s[t.subject] = s[t.subject] ? s[t.subject].concat(t) : [t];
    return s;
  }, {});
};

const publicProgressSerializer = (res) => {
  return res.map(progressConvert);
};

const getProgressSearchString = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const option = urlSearchParams.get('option');
  const datasource = urlSearchParams.get('datasource');
  const sp = new URLSearchParams();
  if (option) {
    sp.set('option', option);
  }
  if (datasource) {
    sp.set('datasource', datasource);
  }
  return sp.toString();
};

export const fetchProgress = async () => {
  const str = getProgressSearchString();
  let url = isOpenApi()
    ? `${API_URL}/internal/progresses`
    : `${API_URL}/api/vendor/progress`;

  if (str !== '') {
    url = url + `?${str}`;
  }
  return request(url, {
    method: 'GET',
  }).then(progressSerializer);
};

export const fetchPublicProgress = async () => {
  const str = getProgressSearchString();
  let url = isOpenApi()
    ? `${API_URL}/internal/progresses`
    : `${API_URL}/api/progress`;

  if (str !== '') {
    url = url + `?${str}`;
  }
  return request(url, {
    method: 'GET',
  }).then(publicProgressSerializer);
};

export const listProgress = async () => {
  const now = new Date();
  const schedules = await getSchedules();
  const normSubjects = schedules[format(now, 'yyyy-MM-dd')] || {};
  const sub = {};
  const nowPeriod = toPeriod(now);
  Object.keys(normSubjects).forEach((s) => {
    const normdoctor = normSubjects[s] || {};
    Object.keys(normdoctor).forEach((d) => {
      const list = [];
      list.push(...normdoctor[d][nowPeriod]);
      sub[s] = list.map(({ doctorName, subjectName, roomId, roomID }) => {
        return {
          id: roomId || roomID,
          date: now,
          time: nowPeriod,
          doctorName: doctorName,
          subject: subjectName,
          room: roomId || roomID,
        };
      });
    });
  });
  return sub;
};
