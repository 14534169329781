import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { Button } from '../../1TalkDesign/Buttons';
import Loading from '../../1TalkDesign/Loading';
import theme from '../../../theme';
import { cancelAppointment } from '../../../services/appointment.service';
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import { t } from 'src/i18n/config';

const CancelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 1.375rem;
  font-weight: 500;
  letter-spacing: 0.07px;
  margin-bottom: 16px;
`;

const CreateButoon = styled(Button)`
  width: 100%;
`;

const Close = styled.i`
  line-height: initial;
  font-size: 1.625rem;
`;
const Description = styled.div`
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: 0.1px;
  color: ${(props) => props.theme.colors.body};
`;

const CancalPage = ({ appointment }) => {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    try {
      setLoading(true);
      await cancelAppointment({ appointment })
        .then(() => {
          return navigate('/appointments/cancel/success', { replace: true });
        })
        .catch((error) => {
          return navigate('/appointments/cancel/failed', {
            state: { message: error.message, contact: error.contact },
            replace: true,
          });
        });
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Modal>
      <ModalHeader>
        <Close onClick={handleBack} className="ri-close-line"></Close>
      </ModalHeader>
      <ModalBody>
        <CancelContainer>
          <Title>{t('cancel')}</Title>
          <Description>{t('confirmCancellation')}</Description>
          <Description>{t('cannotBeUndone')}</Description>
        </CancelContainer>
      </ModalBody>
      <ModalFooter>
        <CreateButoon
          disalbed={loading}
          customizedcolor={theme.colors.error}
          onClick={handleClick}
        >
          {!loading && t('cancelAppointment')}
          {loading && <Loading></Loading>}
        </CreateButoon>
      </ModalFooter>
    </Modal>
  );
};

CancalPage.propTypes = {
  onClickCancel: PropTypes.func,
};

export default CancalPage;
