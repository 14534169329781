import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/layout';
import AppointmentsCancelPage from 'components/appointments/cancel/CancelPage';
import { t } from 'src/i18n/config';

const Cancel = (props) => {
  const { location = {} } = props;
  const { state = { appointment: {} } } = location;
  return (
    <Layout>
      <Helmet>
        <title>{t('myAppointments')}</title>
      </Helmet>
      <AppointmentsCancelPage
        appointment={state.appointment}
      ></AppointmentsCancelPage>
    </Layout>
  );
};

export default Cancel;
