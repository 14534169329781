import { PeriodToTC, compare } from '../../utils/date';
import { t } from 'src/i18n/config';

export const listSerializer = (res) => {
  return res
    .map((obj) => {
      const {
        patientName: name,
        patientAlias: alias,
        appointmentDate,
        appointmentPeriod: time,
        appointmentDoctorName: doctorName,
        appointmentSubjectName: subject,
        appointmentCurrentSeq: currentNumber,
        appointmentSeq: number,
        appointmentWaitingCount: waiting,
        appointmentDeletedAt: deletedAt,
        appointmentTime,
      } = obj;
      const id = obj.appointmentId || obj.appointmentID;
      const room = obj.appointmentRoomId || obj.appointmentRoomID;
      const cancelable = !!obj.appointmentCancelId || !!obj.cancelID;

      return {
        id,
        name,
        relation: alias || t('self'),
        date: new Date(appointmentDate),
        time: PeriodToTC(time) || time,
        doctorName,
        subject,
        room,
        currentNumber,
        number: `${number}`,
        waiting,
        cancelable,
        deleted: deletedAt != null,
        appointmentTime,
      };
    })
    .sort(compare);
};

export const currentProgressSerializer = (list) => (res) => {
  const listByRoomIdDoctorName = (list || []).reduce((s, t) => {
    s[`${t.room}-${t.doctorName}`] = t;
    return s;
  }, {});

  res.forEach((a) => {
    listByRoomIdDoctorName[`${a.roomId}-${a.doctorName}`] = {
      ...listByRoomIdDoctorName[`${a.roomId}-${a.doctorName}`],
      ...a,
    };
  });
  return Object.keys(listByRoomIdDoctorName).map(
    (s) => listByRoomIdDoctorName[s]
  );
};
